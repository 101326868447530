import { PRICE_BUCKET_FILTER_ITEMS, WEIGHT_OBJECT } from 'helpers/constants/product';
import { SORT_BY_OPTIONS } from 'helpers/mocks/product/plp-filter';

export const getFiltersFromURL = (query) => {
  let hadFilters = false;
  const filters = Object.entries(query)
    .filter((item) => item[0].includes('refinementList') && !item[0].includes('percent'))
    .map((item) => {
      const refinementName = item[0].replace('refinementList[', '').replace('][]', '');
      const arrayValues = Array.isArray(item[1]) ? item[1] : (item[1] as string).split(', ');

      switch (refinementName) {
        case 'bucket_price': {
          const values = arrayValues.map(
            (val: string) => PRICE_BUCKET_FILTER_ITEMS.find((price) => price.value === val)?.label,
          );
          return {
            name: refinementName,
            value: values,
          };
        }
        case 'available_weights': {
          const values = arrayValues.map((val: string) => WEIGHT_OBJECT[val]);
          return {
            name: refinementName,
            value: values,
          };
        }
        case 'aggregate_rating': {
          const values = arrayValues.map((val: string) => val + 'star');
          return {
            name: refinementName,
            value: values,
          };
        }
        default:
          return {
            name: refinementName,
            value: arrayValues,
          };
      }
    });

  const sliderFiltersArray = Object.entries(query)
    .filter((item) => item[0].includes('refinementList') && item[0].includes('percent'))
    .map((item) => {
      if (item[0].includes('max')) {
        return { name: item[0].replace('refinementList[', '').replace('][max]', ''), max: item[1] };
      } else {
        return { name: item[0].replace('refinementList[', '').replace('][min]', ''), min: item[1] };
      }
    });

  const groupedData = sliderFiltersArray.reduce((acc, obj) => {
    // @ts-ignore
    const foundIndex = acc.findIndex((item) => item[0].name === obj.name);
    if (foundIndex !== -1) {
      // @ts-ignore
      acc[foundIndex].push(obj);
    } else {
      // @ts-ignore
      acc.push([obj]);
    }
    return acc;
  }, []);

  const sliderFilters = groupedData.map((data) => Object.assign(data[0], data[1]));

  const currentSort = Object.entries(query).filter((item) => item[0].includes('currentSort'));
  const currentSortValue = currentSort.length > 0 && currentSort[0][1];
  const sortLabel = currentSortValue
    ? SORT_BY_OPTIONS.find((opt) => opt.value === currentSortValue)?.label
    : SORT_BY_OPTIONS[0].label;

  if (sliderFilters.length > 0 || filters.length > 0 || currentSortValue) {
    hadFilters = true;
  }

  return { filters, sliderFilters, hadFilters, sortLabel };
};
