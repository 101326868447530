import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { capitalizeLabel } from 'helpers/plp-sort-filters/filter-options';
import { IFilter, ISliderFilters, ISpecials } from 'helpers/types/organisms/drawers/plp-filter-sort';
import CloseIcon from 'public/icons/figma/close-small.svg';
import RatingStar from 'public/icons/figma/rating-star-filled.svg';
import { AppliedFilterButtonRow, AppliedFilterContainer, CTAText } from './styles';

type TProps = {
  selectedFilter: IFilter[];
  sliderFilters: ISliderFilters[];
  specialsData: ISpecials[];
  setNewFilters: (name: string, filterValue: string) => void;
};

const SelectedPills = ({ selectedFilter, setNewFilters, specialsData, sliderFilters }: TProps) => {
  const pillsValues = selectedFilter.map((filter) => {
    return {
      name: filter.name,
      label: (filter.value || []).map((value) => {
        if (filter.name === 'category' || filter.name === 'kind') {
          return capitalizeLabel(value);
        }
        if (filter.name === 'root_types') {
          const label = value.split(/-\d/g)[0];
          return capitalizeLabel(label);
        }
        if (filter.name === 'applicable_special_ids') {
          const special = specialsData.find((special) => special.id.toString() === value);
          return special ? special.title : value;
        }
        if (filter.name === 'aggregate_rating') {
          const rating = value.replace('star', ' and up');
          return (
            <div className="flex items-center gap-2" key={rating}>
              {rating} <RatingStar />
            </div>
          );
        }
        return value;
      }),
      value: filter.value,
    };
  });

  const sliderPills = sliderFilters.map((filter) => {
    return {
      name: filter.name,
      value: [filter.name],
      label: [`${filter.name.split('_')[1].toUpperCase()} ${filter.min}-${filter.max}%`],
    };
  });

  const totalPills = [...pillsValues, ...sliderPills];
  return (
    <AppliedFilterContainer data-testid="applied-filter">
      <CTAText $color="light">Applied filters</CTAText>
      <AppliedFilterButtonRow>
        {totalPills.map((filter, i) => {
          return filter.label.map((value: string, j) => (
            <IconWithTextButton
              button-type="secondary"
              color="green"
              icon={<CloseIcon className="cursor-pointer font-bold text-leaf" size={16} />}
              iconPosition="after"
              key={value}
              onClick={() => {
                if (filter.value) {
                  setNewFilters(filter.name, filter.value[j]);
                }
              }}
              data-testid={`remove-selected-filter-${i}${j}`}
              className="xl:py-3.5 xl:pl-6 xl:pr-[18px]"
            >
              {value}
            </IconWithTextButton>
          ));
        })}
      </AppliedFilterButtonRow>
    </AppliedFilterContainer>
  );
};

export default SelectedPills;
