import tw from 'tailwind-styled-components';

export const BottomDrawerButton = tw.div`
    fixed
    bottom-5
    right-5
    z-[9]
    block
`;

export const DrawerHeader = tw.div`
    flex
    justify-between
    items-center
    border-b
    border-solid
    border-b-grey-200
    p-5
    md:px-[60px]
    md:pb-10
    md:pt-12
`;

export const CTAText = tw.p<{ $color: string }>`
    font-arial
    font-bold
    ${(props) => (props.$color === 'dark' ? 'text-grey-500' : 'text-grey-400 font-normal')}
    text-sm
    leading-4
    tracking-[0.14px]
    md:text-base
    md:leading-5
    md:tracking-[0.16px]
`;

export const DrawerBody = tw.div`
    px-5
    md:px-[60px]
    max-h-full
    overflow-y-scroll
    filter-body
    pb-[160px]
    md:pb-[232px]
`;

export const AppliedFilterContainer = tw.div`
    border-b
    border-solid
    border-b-grey-300
    py-8
    md:py-[48px]
`;

export const AppliedFilterButtonRow = tw.div`
    mt-4
    flex
    flex-wrap
    gap-x-3
    gap-y-4
`;

export const OverlayBox = tw.div`
    absolute
    bottom-0
    h-24
    w-full
    bg-gradient-to-b
    from-white-color-100
    to-white-color-200
    lg:h-[120px]
`;

export const ResultBar = tw.div`
    bg-grey-100
    px-5
    py-4
    absolute
    bottom-0
    w-full
    flex
    items-start
    justify-between
    z-10
    xl:p-7
    xl:pl-[62px]
    xl:pr-[60px]
`;

export const FilterSortBadge = tw.span`
    absolute
    right-[-2px]
    top-[-4px]
    h-[20px]
    w-[20px]
    rounded-[9px]
    bg-resin
    p-[3px]
    text-center
    font-arial
    text-[12px]
    font-bold
    text-white
    md:right-0
    md:h-[22px]
    md:w-[22px]
`;

export const FilterSpacer = tw.div`
    w-full
    h-[90.789px]
    md:h-[80px]
`;
