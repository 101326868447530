'use client';

import { useMemo } from 'react';
import { usePathname } from 'next/navigation';
import { useStats } from 'react-instantsearch';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { cardListFilteredTrack } from 'helpers/analytics/card-list-filtered-track';
import { SORT_BY_OPTIONS } from 'helpers/mocks/product/plp-filter';
import { IFilter, ISliderFilters, TStoredFiltersData } from 'helpers/types/organisms/drawers/plp-filter-sort';
import { ResultBar } from './styles';

type TProps = {
  filterURL: string;
  currentSort: string;
  selectedFilter: IFilter[];
  sliderFilters: ISliderFilters[];
  setCurrentSort: (sort: string) => void;
  setOpenDrawer: (state: boolean) => void;
  setSelectedFilter: (date: IFilter[]) => void;
  setSliderFilters: (date: ISliderFilters[]) => void;
  setStoredFilterData: (date: TStoredFiltersData) => void;
};

const ResultBarComponent = ({
  filterURL,
  currentSort,
  selectedFilter,
  sliderFilters,
  setSelectedFilter,
  setSliderFilters,
  setStoredFilterData,
  setCurrentSort,
  setOpenDrawer,
}: TProps) => {
  const path = usePathname();
  const { nbHits } = useStats();

  const filtersAppliedList = useMemo(() => {
    return [...selectedFilter, ...sliderFilters].map((item) => ({
      filter_type: item.name,
      filter_values: Array.isArray((item as IFilter).value)
        ? //@ts-ignore
          (item as IFilter).value.map((value) => String(value))
        : [
            typeof (item as ISliderFilters).min !== 'undefined' && typeof (item as ISliderFilters).max !== 'undefined'
              ? `${(item as ISliderFilters).min} - ${(item as ISliderFilters).max}%`
              : '',
          ],
    }));
  }, [selectedFilter, sliderFilters]);

  return (
    <ResultBar data-testid="filter-result-bar">
      <CommonButton
        button-type="tertiary"
        color="green"
        className="!pl-0"
        onClick={() => {
          setSelectedFilter([]);
          setSliderFilters([]);
          setStoredFilterData({});
          setCurrentSort(SORT_BY_OPTIONS[0].label);
        }}
      >
        Clear all
      </CommonButton>
      <CommonButton
        button-type="primary"
        color="green"
        onClick={() => {
          const newUrl = (path ?? (path as string).split('?')[0]) + filterURL;
          window.location.href = newUrl;
          setOpenDrawer(false);
          if (selectedFilter.length || sliderFilters.length > 0) {
            const dataInfo = {
              card_list_name: 'Product Listing page',
              card_list_title: '',
              card_list_type: 'grid',
              card_list_facet_name: '',
              card_list_facet_value: '',
              placement: 1,
              filters_applied: filtersAppliedList,
              no_results: nbHits > 0 ? false : true,
            };
            cardListFilteredTrack(dataInfo);
          }
        }}
      >
        {(selectedFilter && selectedFilter.length > 0) || (sliderFilters && sliderFilters.length > 0)
          ? `Show ${nbHits > 999 ? '1K+' : nbHits} results`
          : currentSort !== SORT_BY_OPTIONS[0].label
          ? 'Apply Sort'
          : ''}
      </CommonButton>
    </ResultBar>
  );
};

export default ResultBarComponent;
