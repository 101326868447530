import { ReactElement } from 'react';
import { SORT_BY_OPTIONS, SORT_BY_OPTIONS_OHIO } from 'helpers/mocks/product/plp-filter';
import {
  ICustomRows,
  IFilter,
  IFilterOrder,
  ISliderFilters,
  ISpecials,
  TBaseFiltersData,
  TStoredFiltersData,
} from 'helpers/types/organisms/drawers/plp-filter-sort';
import { store } from 'redux/store';
import FilterWithCheckbox from './filters/filter-with-checkboxes';
import FilterWithPills from './filters/filter-with-pills';
import FilterWithRadio from './filters/filter-with-radio';
import FilterWithSlider from './filters/filter-with-slider';
import FilterWithWeightSelector from './filters/filter-with-weight-selector';

type TProps = {
  currentSort: string;
  filterOrder: IFilterOrder[];
  sliderFilters: ISliderFilters[];
  selectedFilter: IFilter[];
  storedFilterData: TStoredFiltersData;
  baseFilterData: TBaseFiltersData;
  specialsData: ISpecials[];
  getUpdatedFilter: (filter: IFilter) => void;
  getSliderUpdatedFilter: (filter: { name: string; min: number; max: number }) => void;
  setStoredFilterData: (date: TStoredFiltersData) => void;
  setCurrentSort: (sort: string) => void;
  customRows?: ICustomRows[];
};

const FilterBody = ({
  currentSort,
  selectedFilter,
  storedFilterData,
  baseFilterData,
  specialsData,
  getUpdatedFilter,
  getSliderUpdatedFilter,
  setStoredFilterData,
  filterOrder,
  sliderFilters,
  setCurrentSort,
  customRows,
}: TProps) => {
  const isOH = store?.getState()?.hostReducer?.isCurrentHostOH;
  return (
    <>
      <FilterWithRadio
        title="Sort By"
        name="sort_by"
        defaultOpen={true}
        defaultChecked={currentSort}
        options={isOH ? SORT_BY_OPTIONS_OHIO : SORT_BY_OPTIONS}
        setCurrentSort={setCurrentSort}
      />
      {filterOrder.map((order: IFilterOrder) => {
        const attribute = order.name.split(' - ')[1];
        let section: ReactElement;

        switch (attribute) {
          case 'kind':
          case 'category':
          case 'bucket_price':
          case 'root_types':
          case 'applicable_special_ids':
          case 'aggregate_rating':
            section = (
              <FilterWithCheckbox
                title={order.label}
                defaultOpen={order.expand}
                specialsData={specialsData}
                refinementAttribute={attribute}
                updateFilter={getUpdatedFilter}
                filterData={selectedFilter}
                baseOptionsData={baseFilterData[attribute] || []}
                updateFilterOptions={setStoredFilterData}
                freezedFilterOptions={storedFilterData}
                customRows={customRows}
              />
            );
            break;

          case 'brand':
            section = (
              <FilterWithCheckbox
                title={order.label}
                defaultOpen={order.expand}
                refinementAttribute="brand"
                shouldTruncate={true}
                truncateText={{ trueValue: 'Show all brands', falseValue: 'Show less brands' }}
                updateFilter={getUpdatedFilter}
                filterData={selectedFilter}
                baseOptionsData={baseFilterData?.brand || []}
                updateFilterOptions={setStoredFilterData}
                freezedFilterOptions={storedFilterData}
              />
            );
            break;

          case 'available_weights':
            section = (
              <FilterWithWeightSelector
                title={order.label}
                defaultOpen={order.expand}
                updateFilter={getUpdatedFilter}
                filterData={selectedFilter}
                baseOptionsData={baseFilterData?.available_weights || []}
                updateFilterOptions={setStoredFilterData}
                freezedFilterOptions={storedFilterData}
              />
            );
            break;

          case 'activities':
          case 'feelings':
            section = (
              <FilterWithPills
                title={order.label}
                defaultOpen={order.expand}
                refinementAttribute={attribute}
                updateFilter={getUpdatedFilter}
                filterData={selectedFilter}
                baseOptionsData={baseFilterData[attribute] || []}
                updateFilterOptions={setStoredFilterData}
                freezedFilterOptions={storedFilterData}
              />
            );
            break;

          case 'percent_thc':
          case 'percent_cbd':
          case 'percent_thca':
            section = (
              <FilterWithSlider
                title={order.label}
                defaultOpen={order.expand}
                filterData={sliderFilters}
                refinementAttribute={attribute}
                updateFilter={getSliderUpdatedFilter}
              />
            );
            break;

          // case 'aggregate_rating':
          //   section = (
          //     <FilterWithRatings
          //       title={order.label}
          //       defaultOpen={order.expand}
          //       refinementAttribute={attribute}
          //       updateFilter={getUpdatedFilter}
          //       filterData={selectedFilter}
          //       baseOptionsData={baseFilterData[attribute] || []}
          //       updateFilterOptions={setStoredFilterData}
          //       freezedFilterOptions={storedFilterData}
          //     />
          //   );
          //   break;

          default:
            // @ts-ignore
            section = null;
            break;
        }
        return <>{section}</>;
      })}
    </>
  );
};

export default FilterBody;
