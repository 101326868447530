import { ReactNode, useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react/components/Accordion';
import { Typography } from 'components/atoms/typography';
import MinusIcon from 'public/icons/figma/minus.svg';
import PlusIcon from 'public/icons/figma/plus.svg';
import { AccordionIcons } from './styles';

type TProps = {
  title: string;
  defaultOpen: boolean;
  children: ReactNode;
  bodyClassName?: string;
};

const Icon = ({ open }: { open: boolean }) => {
  return (
    <AccordionIcons>{open ? <MinusIcon className="text-leaf" /> : <PlusIcon className="text-leaf" />}</AccordionIcons>
  );
};

const BaseAccordion = ({ defaultOpen = false, title, children, bodyClassName = '' }: TProps) => {
  const [open, setOpen] = useState<boolean>(defaultOpen);

  return (
    <Accordion
      className={`flex flex-col border-b border-solid border-b-grey-300 ${
        open ? 'gap-4 pt-8 md:gap-5 md:py-[30px] lg:py-10' : 'py-[34px] md:py-[40px]'
      }`}
      open={open}
      icon={<Icon open={open} />}
      data-testid="filter-accordion"
    >
      <AccordionHeader data-testid="accordion-header" onClick={() => setOpen(!open)} className="!border-none py-0">
        <Typography variant="h5">{title}</Typography>
      </AccordionHeader>
      <AccordionBody data-testid="accordion-body" className={'flex flex-col gap-0 py-0 md:gap-4 ' + bodyClassName}>
        {children}
      </AccordionBody>
    </Accordion>
  );
};

export default BaseAccordion;
