import { useRef } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { FilterPillButton } from 'components/atoms/buttons/filter-pill';
import { getFilterOptions, getRetainedOptionValue } from 'helpers/plp-sort-filters';
import { IFilter, TBaseFilterOption, TStoredFiltersData } from 'helpers/types/organisms/drawers/plp-filter-sort';
import BaseAccordion from './base-accordion';

type TProps = {
  title: string;
  defaultOpen: boolean;
  updateFilter: ({ name, value }: IFilter) => void;
  filterData: IFilter[];
  refinementAttribute: string;
  baseOptionsData: TBaseFilterOption[];
  freezedFilterOptions?: TStoredFiltersData;
  updateFilterOptions: (date: TStoredFiltersData) => void;
};

const FilterWithPills = ({
  title,
  defaultOpen,
  updateFilter,
  filterData,
  refinementAttribute,
  baseOptionsData,
  freezedFilterOptions,
  updateFilterOptions,
}: TProps) => {
  const firstRender = useRef(true);
  const filterIndex = filterData.findIndex((data) => data.name === refinementAttribute);
  const filters = useRefinementList({ attribute: refinementAttribute, limit: 20 });

  const retainedOptions = getRetainedOptionValue({ firstRender, filters, baseOptionsData, filterIndex });
  const algoliaOpt = getFilterOptions(retainedOptions, { refinementAttribute, addIcons: true });
  let optionData = freezedFilterOptions?.[refinementAttribute] ?? algoliaOpt;

  const clearFreezedOptionData = () => {
    const newFreezedData = freezedFilterOptions;
    if (newFreezedData) {
      delete newFreezedData[refinementAttribute];
      updateFilterOptions(newFreezedData);
    }

    optionData = algoliaOpt;
  };

  if (firstRender.current && baseOptionsData.length > 0) {
    firstRender.current = false;
    if (filterIndex > -1) {
      const newFreezedData = freezedFilterOptions;

      if (newFreezedData) {
        newFreezedData[refinementAttribute] = optionData;
        updateFilterOptions(newFreezedData);
      }
    }
  }

  const onSelect = (e) => {
    const selectedOptions = filterIndex > -1 ? filterData[filterIndex].value : ['all'];
    if (e.name === 'all') {
      updateFilter({ name: refinementAttribute, value: undefined });
      clearFreezedOptionData();
    } else if (selectedOptions && selectedOptions.includes(e.name)) {
      const newOptions = selectedOptions.filter((option) => option !== e.name);
      const isFilterCategoryUnselected = newOptions.length <= 0;
      updateFilter({ name: refinementAttribute, value: isFilterCategoryUnselected ? undefined : newOptions });
      if (isFilterCategoryUnselected) {
        clearFreezedOptionData();
      }
    } else {
      updateFilter({
        name: refinementAttribute,
        value: selectedOptions ? [...selectedOptions.filter((option) => option !== 'all'), e.name] : [],
      });
      if (freezedFilterOptions && !freezedFilterOptions[refinementAttribute]) {
        const newFreezedData = freezedFilterOptions;
        newFreezedData[refinementAttribute] = optionData;
        updateFilterOptions(newFreezedData);
      }
    }
  };

  return (
    <BaseAccordion title={title} defaultOpen={defaultOpen}>
      <div className="flex flex-wrap gap-x-4 gap-y-5">
        {optionData.map((option) => (
          <FilterPillButton
            key={option.label}
            label={`${option.label} ${option.quantity ? `(${option.quantity})` : ''}`}
            icon={option.icon}
            name={option.name}
            //@ts-ignore
            defaultSelected={(filterIndex > -1 ? filterData[filterIndex].value : ['ALL']).includes(option.label)}
            onClick={onSelect}
            disabled={option.quantity === 0 && option.label.toLowerCase() !== 'all'}
          />
        ))}
      </div>
    </BaseAccordion>
  );
};

export default FilterWithPills;
