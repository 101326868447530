'use client';

import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { Drawer } from '@material-tailwind/react/components/Drawer';
import { Configure, InstantSearch } from 'react-instantsearch';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { janeSearchClient } from 'helpers/algolia/clients';
import { getJaneIndexName } from 'helpers/algolia/get-index-name';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import janeStoreApi from 'helpers/jane-store-operations-api';
import { SORT_BY_OPTIONS } from 'helpers/mocks/product/plp-filter';
import { generateFilter, getBaseFilterData, getFiltersFromURL } from 'helpers/plp-sort-filters';
import {
  ICustomRows,
  IFilter,
  IFilterOrder,
  ISliderFilters,
  TBaseFiltersData,
  TStoredFiltersData,
} from 'helpers/types/organisms/drawers/plp-filter-sort';
import { tablet } from 'helpers/utils/screensizes';
import CloseIcon from 'public/icons/figma/close-small.svg';
import FilterIcon from 'public/icons/figma/filter-icon.svg';
import FilterBody from './filter-body';
import ResultBarComponent from './result-bar';
import SelectedPills from './selected-pills';
import {
  BottomDrawerButton,
  CTAText,
  DrawerBody,
  DrawerHeader,
  FilterSortBadge,
  FilterSpacer,
  OverlayBox,
} from './styles';

type TProps = {
  menuID: string;
  filterOrder: IFilterOrder[];
  intialFilters?: IFilter[];
  intialSilderFilters?: ISliderFilters[];
  customRows?: ICustomRows[];
};

const FilterSortDrawer = ({ menuID, intialFilters, intialSilderFilters, filterOrder, customRows }: TProps) => {
  // #36-39 will be removed once CMS changes are done
  const index = filterOrder.findIndex((order) => order.name === 'Category - kind');
  if (filterOrder[index]) {
    filterOrder[index].name = 'Category - root_types';
  }
  const [isTablet] = useMediaQuery(tablet);
  const path = usePathname();
  const urlSelectedFilters = getFiltersFromURL(path);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentSort, setCurrentSort] = useState<string>(urlSelectedFilters.sortLabel as string);
  const [selectedFilter, setSelectedFilter] = useState<IFilter[]>(
    urlSelectedFilters?.filters || intialFilters || ([] as IFilter[]),
  );
  const [sliderFilters, setSliderFilters] = useState<ISliderFilters[]>(
    urlSelectedFilters?.sliderFilters || intialSilderFilters || [],
  );
  const [storedFilterData, setStoredFilterData] = useState<TStoredFiltersData>({});
  const [baseFilterData, setBaseFilterData] = useState<TBaseFiltersData>({});
  const [specialsData, setSpecialsData] = useState([]);

  useEffect(() => {
    const filterAttributes = filterOrder.map((order: IFilterOrder) => order.name.split(' - ')[1]);
    // eslint-disable-next-line @typescript-eslint/ban-types
    getBaseFilterData(menuID, filterAttributes).then((data) => setBaseFilterData(data as {}));
    janeStoreApi.getStoreSpecials(menuID).then((res) => setSpecialsData(res?.data?.data?.specials || []));
  }, [menuID, filterOrder]);

  const getUpdatedFilter = (filter: IFilter) => {
    const newFilters = selectedFilter.filter((f) => f.name !== filter.name);
    if (filter.value === null) {
      setSelectedFilter(newFilters);
    } else {
      setSelectedFilter([...newFilters, filter]);
    }
  };

  const getSliderUpdatedFilter = (filter: ISliderFilters) => {
    const newFilters = sliderFilters.filter((f) => f.name !== filter.name);
    if (filter.min === null && filter.max === null) {
      setSliderFilters(newFilters);
    } else {
      setSliderFilters([...newFilters, filter]);
    }
  };

  const setNewFilters = (name: string, filterValue: string) => {
    if (name.includes('percent')) {
      const newFilters = sliderFilters.filter((filter) => filter.name !== name);
      setSliderFilters(newFilters);
    } else {
      const pulledFilter = selectedFilter.filter((filter) => filter.name === name)[0];
      const newFilterSet = pulledFilter && (pulledFilter.value || [])?.filter((filter) => filter !== filterValue);
      let newGlobalFilters: IFilter[] = [];
      if (newFilterSet.length > 0) {
        newGlobalFilters = [...selectedFilter.filter((filter) => filter.name !== name), { name, value: newFilterSet }];
      } else {
        const newFreezedData = storedFilterData;
        delete newFreezedData[name];
        setStoredFilterData(newFreezedData);
        newGlobalFilters = selectedFilter.filter((filter) => filter.name !== name);
      }
      setSelectedFilter(newGlobalFilters);
    }
  };

  const { filterString, filterURL } = generateFilter(selectedFilter, sliderFilters, currentSort);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    document?.body?.setAttribute('style', 'overflow:visible;');
    if (
      urlSelectedFilters?.hadFilters &&
      selectedFilter.length === 0 &&
      sliderFilters.length === 0 &&
      currentSort === SORT_BY_OPTIONS[0].label
    ) {
      const newUrl = path ?? (path as string).split('?')[0];
      window.location.href = newUrl as string;
    }
  };

  return (
    <>
      <BottomDrawerButton>
        <IconWithTextButton
          icon={<FilterIcon className="h-6 w-6" />}
          iconPosition="after"
          color="green"
          button-type="primary"
          onClick={() => {
            setOpenDrawer(true);
            document?.body?.setAttribute('style', 'overflow:hidden;');
          }}
          data-testid="drawer-button"
          aria-label="Filter and sort"
        >
          <span>Filter and sort</span>
          {((selectedFilter && selectedFilter.length > 0) || (sliderFilters && sliderFilters.length > 0)) && (
            <FilterSortBadge>
              {selectedFilter.map((filter) => (filter.value || []).length).reduce((a, b) => a + b, 0) +
                sliderFilters.length}
            </FilterSortBadge>
          )}
        </IconWithTextButton>
      </BottomDrawerButton>
      {openDrawer && (
        <Drawer
          open={openDrawer}
          onClose={handleCloseDrawer}
          className={
            openDrawer
              ? '!max-h-[95%] w-full rounded-[6px_6px_0px_0px] md:!max-h-full md:!max-w-[796px] md:rounded-none'
              : 'hidden'
          }
          placement={isTablet ? 'right' : 'bottom'}
          overlayProps={{ className: 'bg-overlay !backdrop-blur-none fixed' }}
          data-testid="filter-drawer"
        >
          <DrawerHeader>
            <CTAText $color="dark" data-testid="drawer-heading">
              Filter and sort
            </CTAText>
            <CloseIcon
              className="cursor-pointer text-grey-400"
              size={24}
              data-testid="drawer-close-btn"
              onClick={handleCloseDrawer}
            />
          </DrawerHeader>
          <InstantSearch
            indexName={getJaneIndexName('menu-products')}
            searchClient={janeSearchClient}
            onStateChange={(props) => console.log('🚀 ~ FilterSortDrawer ~ props:', props)}
          >
            <DrawerBody>
              {((selectedFilter && selectedFilter.length > 0) || (sliderFilters && sliderFilters.length > 0)) && (
                <SelectedPills
                  selectedFilter={selectedFilter}
                  setNewFilters={setNewFilters}
                  specialsData={specialsData}
                  sliderFilters={sliderFilters}
                />
              )}
              <Configure
                analytics={false}
                hitsPerPage={2}
                filters={`store_id = ${menuID}${filterString ? ' AND ' + filterString : ''}`}
              />
              <FilterBody
                currentSort={currentSort}
                filterOrder={filterOrder}
                sliderFilters={sliderFilters}
                selectedFilter={selectedFilter}
                storedFilterData={storedFilterData}
                baseFilterData={baseFilterData}
                specialsData={specialsData}
                getUpdatedFilter={getUpdatedFilter}
                getSliderUpdatedFilter={getSliderUpdatedFilter}
                setStoredFilterData={setStoredFilterData}
                setCurrentSort={setCurrentSort}
                customRows={customRows}
              />
              {((selectedFilter && selectedFilter.length > 0) || (sliderFilters && sliderFilters.length > 0)) && (
                <FilterSpacer />
              )}
            </DrawerBody>
            <OverlayBox />
            {((selectedFilter && selectedFilter.length > 0) ||
              (sliderFilters && sliderFilters.length > 0) ||
              currentSort !== SORT_BY_OPTIONS[0].label) && (
              <ResultBarComponent
                filterURL={filterURL}
                currentSort={currentSort}
                selectedFilter={selectedFilter}
                sliderFilters={sliderFilters}
                setCurrentSort={setCurrentSort}
                setOpenDrawer={setOpenDrawer}
                setSelectedFilter={setSelectedFilter}
                setSliderFilters={setSliderFilters}
                setStoredFilterData={setStoredFilterData}
              />
            )}
          </InstantSearch>
        </Drawer>
      )}
    </>
  );
};

export default FilterSortDrawer;
