import { RadioBasic } from 'components/atoms/inputs/radio/radio-list-item';
import { sortSelect } from 'helpers/analytics/sort-select-event';
import BaseAccordion from './base-accordion';
import { RadioWrapper } from './styles';

type TProps = {
  title: string;
  defaultOpen: boolean;
  options: { label: string; value: string }[];
  name: string;
  defaultChecked: string;
  setCurrentSort: (sort: string) => void;
};

const FilterWithRadio = ({ options, title, name, defaultOpen, defaultChecked, setCurrentSort }: TProps) => {
  const handleClick = (value: string) => {
    setCurrentSort(value);
    sortSelect({
      sorted_by: value,
      placement: 3,
      card_list_name: 'Product Listing Page',
      card_list_title: '',
      card_list_type: 'Grid',
    });
  };

  return (
    <BaseAccordion title={title} defaultOpen={defaultOpen} bodyClassName="py-0 md:py-0 gap-0 md:gap-0">
      {options.map((option) => (
        <RadioWrapper key={option.value}>
          <RadioBasic
            label={option.label}
            name={name}
            checked={option.label === defaultChecked}
            className="md:!p-0"
            onClick={handleClick}
          />
        </RadioWrapper>
      ))}
    </BaseAccordion>
  );
};

export default FilterWithRadio;
