import { Rating } from '@material-tailwind/react/components/Rating';
import StarEmptyIcon from 'public/icons/figma/rating-star-empty.svg';
import StarFilledIcon from 'public/icons/figma/rating-star-filled.svg';

const CheckBoxRating = ({ value }: { value?: number }) => {
  return (
    <span className="flex gap-2">
      <Rating
        readonly
        value={value}
        ratedIcon={<StarFilledIcon color="#CF4520" style={{ height: '24px', width: '24px' }} />}
        unratedIcon={<StarEmptyIcon color="#6f7263cc" style={{ height: '24px', width: '24px' }} />}
      />{' '}
      <span className="[flex:_1_0_0]">{value}+</span>
    </span>
  );
};

export default CheckBoxRating;
