import { PRICE_BUCKET_FILTER_ITEMS, WEIGHT_OBJECT } from 'helpers/constants/product';
import { SORT_BY_OPTIONS } from 'helpers/mocks/product/plp-filter';
import { IFilter, ISliderFilters } from 'helpers/types/organisms/drawers/plp-filter-sort';

export const generateFilter = (selectedFilter: IFilter[], sliderFilter: ISliderFilters[], currentSort: string) => {
  const filterStrings: string[] = [];
  const filterStringURL: string[] = [];

  selectedFilter.forEach((filter) => {
    switch (filter.name) {
      case 'bucket_price':
        {
          const filters: string[] = [];
          const urls: string[] = [];

          (filter.value || []).forEach((val) => {
            const bucket = PRICE_BUCKET_FILTER_ITEMS.find((bucket) => bucket.label === val);
            if (bucket && !bucket.end) {
              filters.push(`bucket_price >= ${bucket.start}`);
            } else if (bucket && !bucket.start) {
              filters.push(`bucket_price <= ${bucket.end}`);
            } else {
              bucket && filters.push(`bucket_price: ${bucket.start} TO ${bucket.end}`);
            }
            bucket && urls.push(`refinementList[bucket_price][]=${bucket.value}`);
          });

          filterStrings.push(filters.join(' OR '));
          filterStringURL.push(urls.join('&'));
        }
        break;

      case 'available_weights':
        {
          const filters: string[] = [];
          const urls: string[] = [];

          (filter.value || []).forEach((val) => {
            const row = Object.entries(WEIGHT_OBJECT).find((row) => row.includes(val));
            row && filters.push(`available_weights: "${row[0]}"`);
            row && urls.push(`refinementList[available_weights][]=${row[0]}`);
          });

          filterStrings.push(filters.join(' OR '));
          filterStringURL.push(urls.join('&'));
        }
        break;

      case 'aggregate_rating':
        {
          const filters: string[] = [];
          const urls: string[] = [];

          (filter.value || []).forEach((val) => {
            const row = val.replace('star', '');
            filters.push(`aggregate_rating >= ${row}`);
            urls.push(`refinementList[aggregate_rating][]=${row}`);
          });

          filterStrings.push(`review_count >= 5 AND ${filters.join(' OR ')}`);
          filterStringURL.push(urls.join('&'));
        }
        break;

      default:
        filterStrings.push((filter.value || []).map((val) => `${filter.name}:"${val}"`).join(' OR '));
        filterStringURL.push(
          (filter.value || []).map((val) => `refinementList[${filter.name}][]=${encodeURIComponent(val)}`).join('&'),
        );
        break;
    }
  });

  sliderFilter.forEach((filter: ISliderFilters) => {
    filterStrings.push(`(${filter.name} >= ${filter.min} AND ${filter.name} <= ${filter.max})`);
    filterStringURL.push(
      `refinementList[${filter.name}][max]=${filter.max}&refinementList[${filter.name}][min]=${filter.min}`,
    );
  });

  if (currentSort !== 'A-Z') {
    const sortValue = SORT_BY_OPTIONS.find((opt) => opt.label === currentSort)?.value;
    filterStringURL.push(`currentSort=${sortValue}`);
  }

  const filterString = filterStrings.join(' AND ');
  const filterURL = '?' + filterStringURL.join('&');
  return { filterString, filterURL };
};
