import Blissful from 'public/icons/figma/Blissful.svg';
import Creative from 'public/icons/figma/Creative.svg';
import Easemymind from 'public/icons/figma/Easemymind.svg';
import Energetic from 'public/icons/figma/Energetic.svg';
import Getactive from 'public/icons/figma/Getactive.svg';
import Getintimate from 'public/icons/figma/Getintimate.svg';
import Getrelief from 'public/icons/figma/Getrelief.svg';
import Getsleep from 'public/icons/figma/Getsleep.svg';
import Hangwithfriends from 'public/icons/figma/Hangwithfriends.svg';
import Hungry from 'public/icons/figma/Hungry.svg';
import NotHigh from 'public/icons/figma/NotHigh.svg';
import Painfree from 'public/icons/figma/Painfree.svg';
import Relaxed from 'public/icons/figma/Relaxed.svg';
import Sleepy from 'public/icons/figma/Sleepy.svg';
import Stimulatemymind from 'public/icons/figma/Stimulatemymind.svg';

export const icons: any = {
  Sleepy: <Sleepy />,
  Blissful: <Blissful />,
  Relaxed: <Relaxed />,
  Creative: <Creative />,
  Energetic: <Energetic />,
  Hungry: <Hungry />,
  'Pain free': <Painfree />,
  'Get intimate': <Getintimate />,
  'Get relief': <Getrelief />,
  'Get some sleep': <Getsleep />,
  'Get Active': <Getactive />,
  'Stimulate my mind': <Stimulatemymind />,
  'Hang with friends': <Hangwithfriends />,
  'Ease my mind': <Easemymind />,
  'Not high': <NotHigh />,
};
