'use client';

import React from 'react';
import { Typography } from 'components/atoms/typography';
import CarouselProduct from 'components/organisms/carousel/carousel-product';
import FilterSortDrawer from 'components/organisms/drawers/filter-sort-plp';
import ProductCards from 'components/organisms/listings/product-cards';
import { BannerSection } from 'components/organisms/media/rich-text-component/rich-text-react-render/contentful-render-component';
import { getMenuFiltersLink, getPathMenuFiltersLink } from 'helpers/utils/common';
type Props = {
  pageFolder: {
    dataSourceConfigurations: Array<any>;
    host: string;
  };
};

const ProductListCards = ({ pageFolder }: Props) => {
  const { data, from_deep_link, storeInfo, algolia, algolia_total, algolia_total_page } =
    pageFolder?.dataSourceConfigurations?.find((item) => item?.streamId === '__master')?.preloadedValue;
  const customRows = storeInfo?.custom_rows;
  const filterOrder = data?.pageStateCollection?.items?.[0]?.filtersOrderCollection?.items;
  const { page } = data;
  const totalProductCarousel = page?.items?.[0]?.component?.items?.filter((item) => item?.algolia_total) || [];

  if (from_deep_link) {
    const bannerData =
      page?.items?.[0]?.component?.items?.filter((item) => item?.__typename === 'CommonHeroBanner')[0] || {};

    return (
      <React.Fragment>
        {bannerData && BannerSection(bannerData)}
        <ProductCards
          buttonText="See all product list"
          showTitlePagination={false}
          initialData={algolia}
          totalProductCard={algolia_total}
          totalPages={algolia_total_page}
        />
        <FilterSortDrawer
          menuID={page?.items[0]?.menu?.menuSourceId}
          customRows={customRows}
          filterOrder={filterOrder}
        />
      </React.Fragment>
    );
  } else {
    const _renderProductList = (data, index) => {
      if (data?.__typename === 'CommonHeroBanner') {
        data['host'] = pageFolder?.host;
        return BannerSection(data);
      } else if (data?.cms?.__typename === 'SectionProductCarousel') {
        const { cms, algolia, algolia_total } = data || {};
        const { algoliaId, algoliaValue } = cms?.facetTypeCollection?.items?.[0] || {};
        const button = {
          title: `${cms?.cta?.title ? cms?.cta?.title : 'See All'} ${algolia_total ? `(${algolia_total})` : ''}`,
          url: cms?.cta?.longUrl
            ? getPathMenuFiltersLink(cms?.cta?.longUrl, algoliaId, algoliaValue)
            : getMenuFiltersLink(algoliaId, algoliaValue),
        };

        return (
          algolia.length > 0 && (
            <CarouselProduct
              data={algolia || []}
              type={cms?.carouselVariant}
              title={cms?.heading}
              kind={algolia.variants_details}
              description={cms?.description}
              buttonLink={button.url}
              buttonTitle={button.title}
              order={index + 1}
              algoliaValue={algoliaValue}
            />
          )
        );
      }
    };
    return (
      <React.Fragment>
        {totalProductCarousel?.length > 0 ? (
          page && page?.items?.[0]?.component?.items?.map((item, index) => _renderProductList(item, index))
        ) : (
          <Typography className="w-full px-5 py-10 !text-grey-480  md:w-1/2 xl:px-[60px] xl:py-[120px]" variant="h2">
            {`No exact matches found. Try a similar search with new keywords and hopefully we'll find something for you.`}
          </Typography>
        )}
        <FilterSortDrawer
          menuID={page?.items[0]?.menu?.menuSourceId}
          customRows={customRows}
          filterOrder={filterOrder}
        />
      </React.Fragment>
    );
  }
};

export default ProductListCards;
