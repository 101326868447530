import { MutableRefObject } from 'react';
import { TBaseFilterOption } from 'helpers/types/organisms/drawers/plp-filter-sort';

type TFunctionProps = {
  firstRender: MutableRefObject<boolean>;
  filterIndex: number;
  baseOptionsData: TBaseFilterOption[];
  filters: any;
};

export const getRetainedOptionValue = ({ firstRender, filterIndex, baseOptionsData, filters }: TFunctionProps) => {
  const newOpt =
    firstRender.current && filterIndex > -1
      ? baseOptionsData
      : baseOptionsData.map((option) => {
          const item = filters.items.find((i) => i.value === option.value);
          if (item) {
            return { ...option, count: item.count };
          } else {
            return { ...option, count: 0 };
          }
        });

  return newOpt;
};
