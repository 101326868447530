import { useRef } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { WeightSelectorButton } from 'components/atoms/buttons/weight-selector';
import { getFilterOptions, getRetainedOptionValue } from 'helpers/plp-sort-filters';
import { IFilter, TBaseFilterOption, TStoredFiltersData } from 'helpers/types/organisms/drawers/plp-filter-sort';
import BaseAccordion from './base-accordion';

type TProps = {
  title: string;
  defaultOpen: boolean;
  updateFilter: ({ name, value }: IFilter) => void;
  filterData: IFilter[];
  baseOptionsData: TBaseFilterOption[];
  updateFilterOptions: (date: TStoredFiltersData) => void;
  freezedFilterOptions?: TStoredFiltersData;
};

const FilterWithWeightSelector = ({
  title,
  defaultOpen,
  updateFilter,
  filterData,
  baseOptionsData,
  freezedFilterOptions,
  updateFilterOptions,
}: TProps) => {
  const firstRender = useRef(true);
  const filterIndex = filterData.findIndex((data) => data.name === 'available_weights');
  const filters = useRefinementList({ attribute: 'available_weights', limit: 20 });

  const retainedOptions = getRetainedOptionValue({ firstRender, filters, baseOptionsData, filterIndex });
  const algoliaOpt = getFilterOptions(retainedOptions, { refinementAttribute: 'available_weights' });
  let optionData = (freezedFilterOptions && freezedFilterOptions['available_weights']) ?? algoliaOpt;

  if (firstRender.current && baseOptionsData.length > 0) {
    firstRender.current = false;
    if (filterIndex > -1 && freezedFilterOptions) {
      const newFreezedData = freezedFilterOptions;
      newFreezedData['available_weights'] = optionData;
      updateFilterOptions(newFreezedData);
    }
  }

  const clearFreezedOptionData = () => {
    const newFreezedData = freezedFilterOptions;
    if (newFreezedData) {
      delete newFreezedData['available_weights'];
      updateFilterOptions(newFreezedData);
    }

    optionData = algoliaOpt;
  };

  const onSelect = (e) => {
    const selectedOptions = filterIndex > -1 ? filterData[filterIndex].value : ['ALL'];

    if (e.name === 'ALL') {
      updateFilter({ name: 'available_weights', value: undefined });
      clearFreezedOptionData();
    } else if (selectedOptions && selectedOptions.includes(e.name)) {
      const newOptions = selectedOptions.filter((option) => option !== e.name);
      const isFilterCategoryUnselected = newOptions.length <= 0;
      updateFilter({ name: 'available_weights', value: isFilterCategoryUnselected ? undefined : newOptions });
      if (isFilterCategoryUnselected) {
        clearFreezedOptionData();
      }
    } else {
      updateFilter({
        name: 'available_weights',
        value: selectedOptions ? [...selectedOptions.filter((option) => option !== 'ALL'), e.name] : [],
      });
      if (freezedFilterOptions && !freezedFilterOptions['available_weights']) {
        const newFreezedData = freezedFilterOptions;
        newFreezedData['available_weights'] = optionData;
        updateFilterOptions(newFreezedData);
      }
    }
  };

  return (
    <BaseAccordion title={title} defaultOpen={defaultOpen}>
      <div className="mb-4 flex flex-wrap gap-4 md:mb-6">
        {optionData.map((option) => (
          <WeightSelectorButton
            key={option.label}
            label={option.label}
            refinementValue={option.name}
            //@ts-ignore
            defaultSelected={(filterIndex > -1 ? filterData[filterIndex].value : ['ALL']).includes(option.label)}
            size="sm"
            onClick={onSelect}
            disabled={option.quantity === 0}
          />
        ))}
      </div>
    </BaseAccordion>
  );
};

export default FilterWithWeightSelector;
