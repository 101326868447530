import tw from 'tailwind-styled-components';

export const RangeSliderContainer = tw.div`
    inline-flex
    flex-col
    items-start
    gap-6

    lg:gap-4
    w-full

    [&_div.range-slider-track-0]:bg-grey-300
    [&_div.range-slider-track-2]:bg-grey-300
`;

export const RangeSliderThumb = tw.div`
    bg-leaf
    rounded-[50%]
    cursor-pointer
    h-5
    w-5

    lg:h-4
    lg:w-4

    focus:outline-none

    after:content-[attr(data-range-value)]
    after:top-8
    after:absolute
    after:text-leaf
    after:text-[14px]
    after:font-bold
    after:font-arial
    after:leading-4
    after:tracking-[0.14px]
    after:not-italic

    lg:after:top-10
    lg:after:text-base
    lg:after:leading-[0.16px]
`;

export const TrackStyle = `bg-leaf h-1 top-2 shrink-0 lg:top-1.5 range-slider-track`;

export const RangeSliderInputWrapper = tw.div`
    w-[98%]
    h-12
`;

export const RangeSliderInputStyle = `
    w-full
    h-5
    shrink-0
`;

export const RangeSliderValueContainer = tw.div`
    inline-block
    mt-2.5
`;
