import LeavesIcon from 'public/icons/figma/leaves-icon.svg';

export const SORT_BY_OPTIONS = [
  { label: 'A-Z', value: '' },
  { label: 'Price low to high', value: 'by-price-' },
  { label: 'Price high to low', value: 'by-price-desc-' },
  { label: 'THC% low to high', value: 'by-thc-potency-asc-' },
  { label: 'THC% high to low', value: 'by-thc-potency-desc-' },
  { label: 'Rating', value: 'by-rating-' },
];

export const SORT_BY_OPTIONS_OHIO = [
  { label: 'A-Z', value: '' },
  { label: 'Price low to high', value: 'by-price-' },
  { label: 'Price high to low', value: 'by-price-desc-' },
  { label: 'THC% low to high', value: 'by-thc-potency-asc-' },
  { label: 'THC% high to low', value: 'by-thc-potency-desc-' },
];

export const CATEGORIES_OPTIONS = [
  { name: 'all', label: 'All', quantity: 356 },
  { name: 'flower', label: 'Flower', quantity: 155 },
  { name: 'eddibles', label: 'Eddibles', quantity: 117 },
  { name: 'vape', label: 'Vape', quantity: 26 },
  { name: 'pre-rolls', label: 'Pre-Rolls', quantity: 73 },
  { name: 'cbd', label: 'CBD', quantity: 33 },
  { name: 'drinks', label: 'Drinks', quantity: 33 },
  { name: 'concentrates', label: 'Concentrates', quantity: 5 },
  { name: 'capsules', label: 'Capsules', quantity: 155 },
];

export const LINEAGE_OPTIONS = [
  { name: 'all', label: 'All' },
  { name: 'indica', label: 'Indica' },
  { name: 'sativa', label: 'Sativa' },
  { name: 'hybrid', label: 'Hybrid' },
  { name: 'cbd', label: 'CBD' },
];

export const BRANDS_OPTIONS = [
  { name: 'all', label: 'All' },
  { name: '&Shine', label: '&Shine', quantity: 39 },
  { name: '93 Boyz', label: '93 Boyz', quantity: 3 },
  { name: 'Aeriz', label: 'Aeriz', quantity: 26 },
  { name: 'BedFord', label: 'BedFord', quantity: 73 },
  { name: 'Columbia', label: 'Columbia', quantity: 33 },
  { name: 'Rythym', label: 'Rythym', quantity: 33 },
  { name: 'Dog Walker', label: 'Dog Walker', quantity: 5 },
  { name: 'Thunder & Lightning', label: 'Thunder & Lightning', quantity: 155 },
  { name: 'Fuzed', label: 'Fuzed', quantity: 155 },
];

export const PRICE_OPTIONS = [
  { name: 'all', label: 'All', quantity: 155 },
  { name: 'Under $20', label: 'Under $20', quantity: 117 },
  { name: '$20-$40 ', label: '$20-$40 ', quantity: 26 },
  { name: '$40-$60', label: '$40-$60', quantity: 73 },
  { name: '$60-$80', label: '$60-$80', quantity: 33 },
  { name: 'Above $80 ', label: 'Above $80', quantity: 33 },
];

export const SPECIALS_OPTION = [
  { name: '30% Off - Clear the Vault', label: '30% Off - Clear the Vault', quantity: 155 },
  { name: '25% Off Topicals', label: '25% Off Topicals', quantity: 117 },
  { name: '20% Off Miss Grass', label: '20% Off Miss Grass', quantity: 26 },
  { name: '10% Off Ozone 3.5g', label: '10% Off Ozone 3.5g', quantity: 73 },
];

export const ACTIVITIES_OPTION = [
  { label: 'All' },
  { label: 'Ease my mind (214)', icon: <LeavesIcon /> },
  { label: 'Get relief (117)', icon: <LeavesIcon /> },
  { label: 'Get some sleep (26)', icon: <LeavesIcon /> },
  { label: 'Hang with friends (26)', icon: <LeavesIcon /> },
  { label: 'Get active (411)', icon: <LeavesIcon /> },
  { label: 'Get intimate (35)', icon: <LeavesIcon /> },
];

export const FEELINGS_OPTION = [
  { label: 'All' },
  { label: 'Relaxed (214)', icon: <LeavesIcon /> },
  { label: 'Blissful (117)', icon: <LeavesIcon /> },
  { label: 'Hungry (26)', icon: <LeavesIcon /> },
  { label: 'Pain Free (26)', icon: <LeavesIcon /> },
  { label: 'Energeic (33)', icon: <LeavesIcon /> },
  { label: 'Sleepy (35)', icon: <LeavesIcon /> },
  { label: 'Not High (35)', icon: <LeavesIcon /> },
];

export const WEIGHT_OPTIONS = ['All', '1g', '3.5g', '7g', '14g', '28g'];
