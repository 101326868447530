import { WEIGHT_OBJECT } from 'helpers/constants/product';
import { icons } from 'helpers/constants/review-tags';
import { ICustomRows, ISpecials } from 'helpers/types/organisms/drawers/plp-filter-sort';

type FormatOptions = {
  specialsData?: ISpecials[];
  capitalize?: boolean;
  addIcons?: boolean;
  refinementAttribute?: string;
  customRows?: ICustomRows[];
};

export const capitalizeLabel = (label: string) => {
  if (label === 'cbd') {
    return 'CBD';
  }

  return label
    .split('-')
    .map((word) => word[0]?.toUpperCase() + word?.substring(1))
    .join(' ');
};

export const customRowsOptions = (optionItems: any[], customRowArray?: ICustomRows[]) => {
  const excludedRows = optionItems?.filter((res) => {
    return !customRowArray?.some((data) => {
      return data.row_type === res.label;
    });
  });
  const customeRows = optionItems?.filter((item) => {
    return customRowArray?.some((data) => {
      return data?.row_type === item?.label && data?.enabled;
    });
  });
  return [...customeRows, ...excludedRows];
};

export const getFilterOptions = (
  items: any[],
  options?: FormatOptions,
): {
  label: string;
  name: string;
  quantity: number;
  icon?: string;
}[] => {
  if (items?.length === 0) return [];
  let optionItems = items;
  if (options && options.refinementAttribute === 'root_types') {
    const Obj = items.filter((item) => !item.value.includes(':'));
    const customRow = Obj.filter((obj) => /-\d/.test(obj.label) || obj.label.includes('_')).map((obj) => {
      return {
        label: obj.label.split('-')[0],
        value: obj.value,
        count: obj.count,
      };
    });
    const excludedRows = Obj.filter((obj) => !/-\d/.test(obj.label) && !obj.label.includes('_'));
    optionItems = customRowsOptions([...customRow, ...excludedRows], options?.customRows || []);
  }
  const newItems = optionItems?.map((item: any) => {
    let transformedLabel = '';
    switch (options && options.refinementAttribute) {
      case 'available_weights':
        transformedLabel = WEIGHT_OBJECT[item.label];
        break;

      case 'applicable_special_ids': {
        const specialLabel =
          options &&
          options.specialsData &&
          options.specialsData.find((special) => special.id.toString() === item.label);
        transformedLabel = specialLabel ? specialLabel.title : item.label;
        break;
      }

      default:
        transformedLabel = item.label;
        break;
    }

    return {
      label: options?.capitalize ? capitalizeLabel(transformedLabel) : transformedLabel,
      name: item.value,
      quantity: item.count,
    };
  });

  if (options && options.addIcons) {
    newItems.forEach((item) => {
      item['icon'] = icons[item.label];
    });
  }
  const dataNewItem =
    options?.refinementAttribute === 'available_weights' ? sortByAvailableWeights(newItems) : newItems;
  return [
    {
      label: 'ALL',
      name: 'all',
      quantity: options && options.addIcons ? 0 : newItems?.map((item) => item.quantity)?.reduce((a, b) => a + b, 0),
    },
    ...dataNewItem,
  ];
};

const sortByAvailableWeights = (units) => {
  const numericalGrams =
    units &&
    units?.map((item) => {
      const value = parseFloat(item.label);
      if (!isNaN(value)) {
        return value;
      }
      return 0;
    });
  units.sort((a, b) => {
    const valueA = numericalGrams[units.indexOf(a)];
    const valueB = numericalGrams[units.indexOf(b)];
    return valueA - valueB;
  });

  return units;
};

export const getPriceOptions = (item: any[]) => {
  const priceRanges = [20, 40, 60, 80];
  const newItems = [
    { name: 'Under $20', label: 'Under $20', quantity: 0 },
    { name: '$20-$40', label: '$20-$40 ', quantity: 0 },
    { name: '$40-$60', label: '$40-$60', quantity: 0 },
    { name: '$60-$80', label: '$60-$80', quantity: 0 },
    { name: 'Above $80', label: 'Above $80', quantity: 0 },
  ];

  item.forEach((item: any) => {
    const val = parseInt(item.value);
    const index = priceRanges.findIndex((range) => val < range);

    if (index !== -1) {
      newItems[index].quantity += item.count;
    } else {
      newItems[newItems.length - 1].quantity += item.count;
    }
  });

  return [
    {
      label: 'ALL',
      name: 'all',
      quantity: newItems.map((item) => item.quantity).reduce((a, b) => a + b, 0),
    },
    ...newItems,
  ];
};

export const getSortAndMoveAllFirst = (inputArray) => {
  const allItem = inputArray?.find((item) => item?.name === 'all');
  const sortedArray = inputArray
    ?.filter((item) => item?.name !== 'all')
    .sort((a, b) => a?.name?.localeCompare(b?.name));
  if (allItem) {
    sortedArray?.unshift(allItem);
  }
  return sortedArray || [];
};
