import React, { useRef, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import CheckboxList from 'components/atoms/inputs/checkbox/checkbox-list-item';
import CheckBoxRating from 'components/atoms/inputs/checkbox/checkbox-rating-icon';
import {
  getFilterOptions,
  getPriceOptions,
  getRatingOptions,
  getRetainedOptionValue,
  getSortAndMoveAllFirst,
} from 'helpers/plp-sort-filters';
import {
  ICustomRows,
  IFilter,
  ISpecials,
  TBaseFilterOption,
  TStoredFiltersData,
} from 'helpers/types/organisms/drawers/plp-filter-sort';
import ChevronDownIcon from 'public/icons/figma/chevron-down.svg';
import ChevronUpIcon from 'public/icons/figma/chevron-up.svg';
import BaseAccordion from './base-accordion';
import { CheckboxesWrapper } from './styles';

type TProps = {
  title: string;
  defaultOpen: boolean;
  refinementAttribute?: string;
  shouldTruncate?: boolean;
  truncateText?: {
    trueValue: string;
    falseValue: string;
  };
  specialsData?: ISpecials[];
  updateFilter: ({ name, value }: IFilter) => void;
  filterData: IFilter[];
  baseOptionsData: TBaseFilterOption[];
  freezedFilterOptions: TStoredFiltersData;
  updateFilterOptions: (date: TStoredFiltersData) => void;
  customRows?: ICustomRows[];
};

const FilterWithCheckbox = ({
  title,
  defaultOpen,
  shouldTruncate,
  truncateText,
  updateFilter,
  filterData,
  refinementAttribute,
  baseOptionsData,
  freezedFilterOptions,
  updateFilterOptions,
  specialsData,
  customRows,
}: TProps) => {
  const firstRender = useRef(true);
  const [isTruncate, setIsTruncate] = useState<boolean>(shouldTruncate as boolean);
  const filterIndex = filterData.findIndex((data) => data.name === refinementAttribute);
  const filters = useRefinementList({ attribute: refinementAttribute || 'brand', limit: 200 });

  const retainedOptions = getRetainedOptionValue({ firstRender, filters, baseOptionsData, filterIndex });
  let algoliaOpt;

  switch (refinementAttribute) {
    case 'bucket_price':
      algoliaOpt = getPriceOptions(retainedOptions);
      break;
    case 'aggregate_rating':
      algoliaOpt = getRatingOptions(retainedOptions);
      break;
    default:
      algoliaOpt = getFilterOptions(retainedOptions, {
        specialsData,
        refinementAttribute,
        capitalize: refinementAttribute !== 'brand',
        customRows,
      });
  }

  let optionData = freezedFilterOptions[refinementAttribute as string] ?? algoliaOpt;

  const clearFreezedOptionData = () => {
    const newFreezedData = freezedFilterOptions;
    delete newFreezedData[refinementAttribute as string];

    console.log('newFreezedData', newFreezedData);
    updateFilterOptions(newFreezedData);
    optionData = algoliaOpt;
  };

  if (firstRender.current && baseOptionsData.length > 0) {
    firstRender.current = false;
    if (filterIndex > -1) {
      const newFreezedData = freezedFilterOptions;
      newFreezedData[refinementAttribute as string] = optionData;
      updateFilterOptions(newFreezedData);
    }
  }

  const onSelect = (e) => {
    const selectedOptions = filterIndex > -1 ? filterData[filterIndex].value : ['all'];
    if (e.name === 'all') {
      updateFilter({ name: refinementAttribute as string, value: undefined });
      clearFreezedOptionData();
    } else if (selectedOptions && selectedOptions.includes(e.name)) {
      const newOptions = selectedOptions.filter((option) => option !== e.name);
      const isFilterCategoryUnselected = newOptions.length <= 0;

      console.log('newOptions', newOptions);

      updateFilter({ name: refinementAttribute as string, value: isFilterCategoryUnselected ? undefined : newOptions });

      if (isFilterCategoryUnselected) {
        clearFreezedOptionData();
      }
    } else {
      updateFilter({
        name: refinementAttribute as string,
        value: selectedOptions ? [...selectedOptions.filter((option) => option !== 'all'), e.name] : [],
      });
      if (!freezedFilterOptions[refinementAttribute as string]) {
        const newFreezedData = freezedFilterOptions;
        newFreezedData[refinementAttribute as string] = optionData;
        updateFilterOptions(newFreezedData);
      }
    }
  };

  const data = refinementAttribute === 'brand' ? getSortAndMoveAllFirst(optionData) : optionData;

  return (
    data?.length > 0 && (
      <BaseAccordion data-testid="filter-with-checkbox" title={title} defaultOpen={defaultOpen}>
        <div className={'flex flex-col gap-0 md:gap-4 ' + (isTruncate ? '[&>*:nth-child(n+7)]:hidden' : '')}>
          {data?.map((option) => {
            const aggregateLabel = option.label.includes('star') ? (
              <CheckBoxRating value={parseInt(option.label.replace('star', ''))} />
            ) : (
              option.label
            );
            const quantityLabel = refinementAttribute !== 'category' && option?.quantity ? `(${option?.quantity})` : '';
            const formatedLabel =
              refinementAttribute === 'aggregate_rating' ? aggregateLabel : `${option?.label} ${quantityLabel}`;

            return (
              <CheckboxesWrapper key={option?.label}>
                <CheckboxList
                  label={formatedLabel}
                  name={option?.name}
                  // @ts-ignore
                  defaultChecked={(filterIndex > -1 ? filterData[filterIndex].value : ['all']).includes(option?.name)}
                  onChange={onSelect}
                  disabled={option?.quantity === 0}
                />
              </CheckboxesWrapper>
            );
          })}
        </div>
        {shouldTruncate && (
          <IconWithTextButton
            button-type="tertiary"
            color="green"
            iconPosition="after"
            icon={isTruncate ? <ChevronDownIcon size={20} /> : <ChevronUpIcon size={20} />}
            className="!pl-0 md:!pl-0 lg:!pl-0"
            onClick={() => setIsTruncate(!isTruncate)}
            data-testid="show-more-less-btn"
          >
            {isTruncate ? truncateText?.trueValue : truncateText?.falseValue}
          </IconWithTextButton>
        )}
      </BaseAccordion>
    )
  );
};

export default FilterWithCheckbox;
