export const getJaneIndexName = (indexName: string): string => {
  return `${indexName}-${process.env.NEXT_PUBLIC_IHJ_INX_ENV === 'production' ? 'production' : 'staging'}`;
};

export const getGTIIndexName = (indexName: string): string => {
  let suffix = '';
  switch (process.env.NEXT_PUBLIC_CUST_ENV) {
    case 'production':
      suffix = 'production';
      break;
    case 'staging':
      suffix = 'staging';
      break;
    default:
      suffix = 'dev';
  }
  return `${indexName}-${suffix}`;
};
