import algoliasearch from 'algoliasearch';
import { getGTIIndexName, getJaneIndexName } from './get-index-name';

// JANE ALGOLIA search client
export const janeSearchClient = algoliasearch(
  process.env.NEXT_PUBLIC_IHJ_AL_APP_ID || '',
  process.env.NEXT_PUBLIC_IHJ_AL_API_KEY || '',
);

// GTI ALGOLIA search client - Commented till keys are acquired.
export const gtiSearchClient = algoliasearch(
  process.env.NEXT_PUBLIC_GTI_AL_APP_ID || '',
  process.env.NEXT_PUBLIC_GTI_ALGOLIA_API_KEY || '',
);

// Indexes
export const productMenuIndex = janeSearchClient.initIndex(getJaneIndexName('menu-products'));
export const sitePageIndex = gtiSearchClient.initIndex(getGTIIndexName('site-pages'));
export const reviewIndex = janeSearchClient.initIndex(getJaneIndexName('product-reviews'));
export const dispensaryIndex = gtiSearchClient.initIndex(getGTIIndexName('dispensary'));
