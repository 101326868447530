import { useEffect, useState } from 'react';
import { useRange } from 'react-instantsearch';
import RangeSlider from 'components/atoms/inputs/range-slider';
import { ISliderFilters } from 'helpers/types/organisms/drawers/plp-filter-sort';
import BaseAccordion from './base-accordion';

interface RangeSliderValueProps {
  min: number;
  max: number;
}

type TProps = {
  title: string;
  defaultOpen: boolean;
  initialValue?: RangeSliderValueProps;
  refinementAttribute?: string;
  filterData: ISliderFilters[];
  updateFilter: (filter: ISliderFilters) => void;
};

const FilterWithSlider = ({
  title,
  defaultOpen,
  initialValue,
  refinementAttribute,
  updateFilter,
  filterData,
}: TProps) => {
  const [freezeRange, setFreezeRange] = useState<RangeSliderValueProps>();
  const { range } = useRange({ attribute: refinementAttribute as string });
  const newRange = freezeRange || (range as RangeSliderValueProps);

  const { max, min } = newRange;
  const [rangeValue, setRangeValue] = useState<RangeSliderValueProps>(
    (initialValue || newRange) as RangeSliderValueProps,
  );
  const filterIndex = filterData.findIndex((data) => data.name === refinementAttribute);

  useEffect(() => {
    if (filterIndex > -1) {
      const newMax = Math.min(newRange.max as number, filterData[filterIndex].max as number) || 0;
      const newMin = Math.max(newRange.min as number, filterData[filterIndex].min as number);
      setRangeValue({
        max: newMax,
        min: newMin,
      });
    } else {
      setRangeValue(newRange);
    }
  }, [newRange, filterData, refinementAttribute, filterIndex]);

  const handleChange = (e: RangeSliderValueProps) => {
    setRangeValue(e);
    if (e.min !== min || e.max !== max) {
      updateFilter({ name: refinementAttribute as string, min: e.min, max: e.max });
      if (filterIndex === -1) setFreezeRange({ min: min || 0, max: max || 0 });
    } else {
      updateFilter({ name: refinementAttribute as string, min: null, max: null });
      setFreezeRange(undefined);
    }
  };

  return (
    <BaseAccordion title={title} defaultOpen={defaultOpen}>
      <RangeSlider min={min || 0} max={max || 0} step={1} value={rangeValue} onChange={handleChange} />
    </BaseAccordion>
  );
};

export default FilterWithSlider;
