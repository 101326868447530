import { productMenuIndex } from 'helpers/algolia/clients';

export const getBaseFilterData = async (menuID: string, filterAttributes: string[]) => {
  try {
    const res = await productMenuIndex.search('', {
      facets: ['*'],
      filters: `store_id = ${menuID}`,
    });

    if (res) {
      const data = res.facets;
      const finalData = {};
      filterAttributes.forEach((key) => {
        if (data && data[key]) {
          const newData = Object.entries(data[key]).map(([name, count]) => {
            return {
              value: name,
              label: name,
              count,
            };
          });
          finalData[key] = newData;
        }
      });
      return finalData || {};
    }
  } catch (error) {
    console.error('Error fetching filter data:', error);
    return {}; // or handle error accordingly
  }
};
