import { SegmentEvents } from 'helpers/constants/forms';
import { ICardListFilteredEvent, SegementTrackEvent } from './segment-helper';

export const cardListFilteredTrack = (value) => {
  try {
    const data: ICardListFilteredEvent = {
      page_location: typeof window !== 'undefined' && window.location.href,
      ...value,
    };
    return SegementTrackEvent(SegmentEvents.CARD_LIST_FILTERED, data, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};
