export const getRatingOptions = (item: any[]) => {
  const newItems = [
    { name: '1star', label: '1star', quantity: 0 },
    { name: '2star', label: '2star', quantity: 0 },
    { name: '3star', label: '3star', quantity: 0 },
    { name: '4star', label: '4star', quantity: 0 },
  ];

  item.forEach((item: any) => {
    const val = parseFloat(item.value);
    newItems.forEach((range) => {
      if (val >= parseInt(range.name)) {
        range.quantity += item.count;
      }
    });
  });

  return [
    {
      label: 'ALL',
      name: 'all',
      quantity: newItems.map((item) => item.quantity).reduce((a, b) => a + b, 0),
    },
    ...newItems,
  ];
};
