import { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import { Typography } from 'components/atoms/typography';
import {
  RangeSliderContainer,
  RangeSliderInputStyle,
  RangeSliderInputWrapper,
  RangeSliderThumb,
  RangeSliderValueContainer,
  TrackStyle,
} from './styles';

interface RangeSliderValueProps {
  min: number;
  max: number;
}
export interface RangeSliderProps {
  min: number;
  max: number;
  value?: RangeSliderValueProps;
  step?: number;
  leftThumbArialLabel?: string;
  rightThumbArialLabel?: string;
  pearling?: boolean;
  className?: string;
  onChange?: (obj: RangeSliderValueProps) => void;
  thumbValueBg?: string;
}

const RangeSlider = ({
  min = 0,
  max = 100,
  value,
  step = 1,
  onChange,
  leftThumbArialLabel = '',
  rightThumbArialLabel = '',
  pearling = false,
  thumbValueBg = 'after:bg-white',
}: RangeSliderProps) => {
  const [minValue, setMinValue] = useState(value?.min ? value.min : min);
  const [maxValue, setMaxValue] = useState(value?.max ? value.max : max);

  useEffect(() => {
    if (value) {
      setMinValue(value.min);
      setMaxValue(value.max);
    }
  }, [value]);

  return (
    <RangeSliderContainer data-testid="range-slider">
      <div className="w-full">
        <Typography variant="body-small" className="!text-grey-400">
          Between {minValue}-{maxValue}%
        </Typography>
      </div>
      <RangeSliderInputWrapper>
        <ReactSlider
          min={min}
          max={max}
          value={[value?.min || min, value?.max || max]}
          className={RangeSliderInputStyle}
          trackClassName={TrackStyle}
          defaultValue={[value?.min || min, value?.max || max]}
          ariaLabel={[leftThumbArialLabel, rightThumbArialLabel]}
          ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
          renderThumb={(props, state) => {
            const value = min !== minValue || max !== maxValue ? `${state.valueNow}%` : '';
            const thumbStyle =
              (state.index ? 'after:-right-[3px] lg:after:-right-px ' : 'after:left-0 ') + thumbValueBg;
            return minValue === 0 && maxValue === 0 ? null : (
              <RangeSliderThumb data-range-value={value} {...props} className={thumbStyle} />
            );
          }}
          pearling={pearling}
          minDistance={step}
          onChange={(value) => {
            const min = value[0];
            const max = value[1];
            if (onChange) onChange({ min, max });
            setMinValue(min);
            setMaxValue(max);
          }}
        />
        <div>
          <RangeSliderValueContainer>
            <Typography variant="body-small" className="!text-grey-400">
              {min}%
            </Typography>
          </RangeSliderValueContainer>
          <RangeSliderValueContainer className="float-right">
            <Typography variant="body-small" className="!text-grey-400">
              {max}%
            </Typography>
          </RangeSliderValueContainer>
        </div>
      </RangeSliderInputWrapper>
    </RangeSliderContainer>
  );
};

export default RangeSlider;
