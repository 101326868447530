import axios from 'axios';

const STORE_OPERATION_API = 'store_operations_api/v1/stores';

const janeRootsClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_IHJ_PROXY_HOST + '/roots',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const validStatus = (status: number) => {
  return (status >= 200 && status <= 299) || status >= 400 || status <= 404;
};

const authenticateClient = async () => {
  const clientId = process.env.NEXT_PUBLIC_IHJ_ID || '';
  const clientSecret = process.env.NEXT_PUBLIC_IHJ_SECRET || '';

  const resp = await axios({
    validateStatus: validStatus,
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_IHJ_PROXY_HOST}/oauth/token?grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}`,
  });

  return resp.data.access_token;
};

janeRootsClient.interceptors.request.use(
  async (req) => {
    const token = await authenticateClient();
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  },
  (err) => {
    return Promise.reject(err);
  },
);

const getJaneProduct = async (storeId: string, productId: string) => {
  try {
    return await janeRootsClient.get(`${STORE_OPERATION_API}/${storeId}/products/${productId}`);
  } catch (error) {
    console.log('Jane GetProduct Err:-', error);
  }
};

const getJaneStoreInfo = async (storeId: string) => {
  try {
    return await janeRootsClient.get(`${STORE_OPERATION_API}/${storeId}`);
  } catch (error) {
    console.log('Jane GetProduct Err:-', error);
  }
};

const getStoreSpecials = async (storeId: string) => {
  try {
    return await janeRootsClient.get(`${STORE_OPERATION_API}/${storeId}/specials?enabled=true`);
  } catch (error) {
    return error;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getJaneProduct, getJaneStoreInfo, getStoreSpecials };
