import tw from 'tailwind-styled-components';

export const AccordionIcons = tw.div`
    h-6
    w-6
    transition-transform
`;

export const CheckboxesWrapper = tw.div`
    border-b
    border-solid
    border-b-grey-280
    py-5
    md:py-0
    md:border-none
    last:border-none
`;

export const RadioWrapper = tw.div`
    border-b
    border-solid
    border-b-grey-280
    md:border-none
    last:border-none
`;
